export function sendGtmEvent(trigger: string) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'trigger_handled',
    gtm_trigger: trigger,
  })
}

export function sendCompanyId(companyId: number) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    company_id: companyId,
  })
}
